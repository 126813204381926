import { Link } from 'gatsby'
import { StaticImage, getImage } from 'gatsby-plugin-image'

import solutionBgRing from '~/assets/images/case-study/xpc/bg-solution-rings.svg?file'
import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import SubtractBlock from '~/components/shared/SubtractBlock'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'

import useXpcStaticQuery from './useXpcStaticQuery'
import * as containerStyles from './XPC.module.scss'

const quotes = {
  fe: {
    name: 'Eugene',
    position: 'Tech Lead & React expert at Codica',
  },
}

const previewAfricarTitle = 'Multi-vendor vehicle marketplace'
const previewDVTitle = 'Online collaboration marketplace'

const CaseStudiesXpc = () => {
  const query = useXpcStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFourNew = getImage(
    fileToImageLikeData(query.solutionDeliveredFourNew),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )

  return (
    <MainLayout isTransparent scrollTop>
      <CaseStudyHead
        title="Accommodation Search for Expats Website MVP"
        subtitle="Learn how Codica helped a client to quickly build an MVP of accommodation search website for expats."
        image={bannerCase}
        classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
        imageTitle="Complex Accommodation Search Website MVP"
        imageAlt="Accommodation Search Website MVP"
        domain="Accommodation"
        location="Australia"
        timeline="Feb 2019 - May 2019"
        services={
          <>
            <Link to="/services/ui-ux-design/" key="designKey">
              UX/UI design services
            </Link>
            , Front-end and Back-end development,
            <Link
              to="/services/mvp-development/"
              className="ml5"
              key="mvpDevKey"
            >
              MVP development
            </Link>
          </>
        }
        team="1 Project Manager, 1 UX/UI Designer, 2 React Developers, 1 QA Engineer"
        technologies="React, Redux, ANTdesign / Semantic, CSS animations"
      />

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDark}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft="This project is a world-first service for finding long-term accommodation. The company helps people who move to Australia to take up employment find a residence quickly."
        bgTextRight="The goal of the project is to simplify the relocation process by assisting in accommodation search for expats."
        sectionBusiness={[
          {
            label:
              'Build a minimum viable product of an accommodation-picking platform in a short time frame.',
          },
          {
            label:
              'Create a fast-loading house rent website for expats to find the most suitable accommodation that meets specific requirements.',
          },
          {
            label:
              'Find the most convenient way to collect search criteria from users to offer them suitable accommodation.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Our team delivered a working minimum viable product that our client could present to early investors, without spending too much time and money on web development.',
          },
          {
            label:
              'The expat housing platform allows quickly finding the most relevant accommodation options with the help of the search wizard, and further downloading the results as a PDF file.',
          },
          {
            label:
              'To ensure high website performance, we used Firebase for authentication and storing users’ data, thus avoiding numerous API requests.',
          },
        ]}
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“It was a real pleasure to work on such an interesting project. On the technical side, it was great to work with Firebase, Google Maps Directions and Distance Matrix APIs and deepen our expertise.',
            },
            {
              text: 'Our team was truly inspired by the idea of building an MVP for a search expat housing platform. And for me, that’s the beauty of software development - creating powerful solutions to the real problems that people encounter. It was a really valuable experience for our team.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/FE-Eugene.png"
              alt={`${quotes.fe.name}, ${quotes.fe.position} at Codica`}
              title={`${quotes.fe.name}, ${quotes.fe.position}`}
              width={80}
              height={80}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.fe.name}
          position={quotes.fe.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Have a great idea for a web product?"
        description="We know how to bring it life."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Complex accommodation search for expats"
          description="When users put in their criteria, the application displays a local map with relevant and available settlement options. Users can dynamically change available filters to narrow the accommodation search for expats or see the most efficient way from home to work."
          alt="Accommodation search system for expats"
          imageTitle="Complex accommodation search system for expats"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="UX and UI"
          description="We used Semantic UI to build a visually-appealing UI design of the MVP solution according to the company's identity. Thanks to the intuitive user flow, the interface is convenient and easy-to-use, which boosts user experience of the expat housing platform."
          alt="UX/UI design of complex accommodation search system for expats"
          imageTitle="Intuitive UX/UI design of complex accommodation search system for expats"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Google Maps Integration"
          description="Customers see custom styled pop-ups for accommodation options on the local map in real-time mode. The windows display the main lodging description with several images, address, the price per week, and included house facilities."
          alt="Google Maps Integration"
          imageTitle="Google Maps Integration by Codica"
          bgRings
          bgRingImage={solutionBgRing}
          bgRingClass={containerStyles.caseStudySolution__ring}
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredFourNew}
          title="Search wizard"
          description="Search wizard allows users to specify all their preferences in a quiz with animation effects. When the wizard is submitted, expats will see the most relevant accommodation offers. Users can further download PDF reports which contain specified attributes, relevant neighbourhoods, and available residences."
          alt="Search wizard for specifying users' preferences"
          imageTitle="Search wizard used to specify users' preferences"
        />
        <CaseStudySolution
          image={solutionDeliveredFive}
          title="Google Distance Matrix API"
          description="One of the great features of the search expat housing platform is that expats can see the most accurate routes from a selected house to a specific destination point, with distance and duration indicators. To build this functionality, we used Google Distance Matrix API. To ensure its fast work, we used Firebase to store and cache the preferences. It helps avoid making tons of API requests each time users change attributes."
          alt="Google Distance Matrix API for building a route for expats"
          imageTitle="Google Distance Matrix API for expats route planning"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredSix}
          title="Attribute ranking algorithm to simplify accommodation search for expats"
          description="Our team built an attribute ranking algorithm that allows us to dynamically generate the most suitable lodging for users based on the specified attributes. This mechanism helps narrow the search and offer the most accurate and needed option."
          alt="Attribute ranking algorithm to generate the required accommodation options"
          imageTitle="Attribute ranking algorithm for generating suitable accommodation options"
        />
      </div>

      <div className={containerStyles.caseStudyTech}>
        <CaseStudyTech
          technologies={[
            { label: 'React', icon: 'react' },
            { label: 'Redux', icon: 'redux' },
            { label: 'ANTdesign', icon: 'antdesign' },
            { label: 'POSE', icon: 'pose' },
            { label: 'Firebase', icon: 'firebase' },
            { label: 'React Google maps', icon: 'reactGoogleMaps' },
            { label: 'React Geosuggest', icon: 'reactGeosuggest' },
            { label: 'CSS animations', icon: 'cssAnimations' },
          ]}
          integrations={[
            { label: 'Maps JS API', icon: 'mapsJsApi' },
            { label: 'Places API', icon: 'placesApi' },
            { label: 'Geocoding API', icon: 'geocodingApi' },
            { label: 'Directions API', icon: 'directionsApi' },
            { label: 'Distance Matrix API', icon: 'distanceMatrixApi' },
          ]}
        />
        <div className="d-none d-lg-block">
          <SubtractBlock />
        </div>
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s build a great custom product together!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewAfricarTitle}
              color="africarColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-africar.png"
                  alt={`Case study: ${previewAfricarTitle} | Codica`}
                  title={`Case study: ${previewAfricarTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                  width={350}
                />
              }
              url="multi-vendor-vehicle-marketplace"
            />
            <CaseStudyPreview
              title={previewDVTitle}
              color="dvColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-digital-village.png"
                  alt={`Case study: ${previewDVTitle} | Codica`}
                  title={`Case study: ${previewDVTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                />
              }
              url="collaboration-marketplace-and-smart-contract-system"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesXpc
